import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogHero = ({ title, createdDate, featuredImage, category }) => {
  const image = getImage(featuredImage);
  return (
    <div>
      <header className="single-post__header">
        <div className="article-card__meta entry-meta">
          <span className="updated">{createdDate}</span>
          <div className="categories">{category}</div>
        </div>
        <h1 className="single-post__title entry-title display-1">{title}</h1>
      </header>
      <div className="single-post__content entry-content">
        <GatsbyImage image={image} alt={title} />
      </div>
    </div>
  );
};

export default BlogHero;
